import React from "react";
import Menu from "../components/Menu";
import Header from "../components/Header";
import Main from "../components/Main";
import "../styles/Home.css";

function POP() {
    return (
        <div className="home">
        <Menu />
        <Header />

        {/* <div className="main"> */}

        </div>
    );
    }

export default POP;