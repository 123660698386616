
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Payment from './pages/Payment';
import Login from './components/Login';
import POP from './pages/POP';
import ReactGA from 'react-ga4';


import React, { useState} from "react";
// import { initMercadoPago } from "@mercadopago/sdk-react";
// import Payment from "./components/Payment";
// import Checkout from "./components/Checkout";
// import InternalProvider from "./components/ContextProvider";
// import { SpinnerCircular } from 'spinners-react';

// REPLACE WITH YOUR PUBLIC KEY AVAILABLE IN: https://developers.mercadopago.com/panel
// initMercadoPago("APP_USR-26228224-c911-4e1c-9509-180824f74013");

function App() {
  ReactGA.initialize('G-WT64ZM4V4Y'); 
  // const [preferenceId, setPreferenceId] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  // const [orderData, setOrderData] = useState({ quantity: "1", price: "10", amount: 10, description: "Some book" });
  
  // const handleClick = () => {
  //   setIsLoading(true);
  //   fetch("http://localhost:8080/create_preference", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(orderData),
  //   })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((preference) => {
  //       setPreferenceId(preference.id);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     }).finally(() => {
  //       setIsLoading(false);
  //     })
  // };

  // const renderSpinner = () => {
  //    if (isLoading) {
  //     return (
  //       <div className="spinner-wrapper">
  //         <SpinnerCircular сolor='#009EE3' />
  //       </div>
  //     )
  //    }
  // }


  return (
  //   <InternalProvider context={{ preferenceId, isLoading, orderData, setOrderData }}>
  //   <main>
  //     {renderSpinner()}
  //     <Checkout onClick={handleClick} description/>
  //     <Payment />
  //   </main>
  //   {/* <Footer /> */}
  // </InternalProvider>
   
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/pop" element={<POP />} />
      </Routes>
    </Router>
  );
}

export default App;