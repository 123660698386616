import React from 'react';
import '../styles/About.css'; // Supondo que você tenha um arquivo CSS para estilos

function About() {
  return (
    <div className="about">

        <div className="aboutleft"> <div style={{flex:2}}>
      <h1>RenderRefine: A IA para render que te ajuda a ganhar tempo de verdade 🤓</h1>
      <p>A IA generativa é uma ferramenta revolucionária, mas usar no dia-a-dia ainda é um desafio?<br/><br/>Aqui na RenderRefine nós entendemos o que você realmente precisa e focamos na sua produtividade. Sem ter que aprender novas ferramentas ou teorias, sem prompts ou complicações. <br/><br/>Tão simples quanto você precisa.</p>
      </div><div style={{flex:3}}>
      <h2 style={{textAlign:"center"}}>Por Que Usar RenderRefine?</h2>
      <ul>
        <li>Crie imagens instantâneas e de alta qualidade a partir de um esboço tridimensional, com baixo custo.</li>
        <li>Economize tempo: sem instalações e sem utilizar processamento do seu computador. Simplesmente arraste e solte.</li>
        <li>Comece facilmente e mude sua produtividade instantaneamente.</li>
        <li>Crie imagens com direitos de uso inclusos. Todos os planos permitem uso pessoal e comercial.</li>
        <li>Fique por dentro das últimas novidades sobre inovações em renderização com ferramentas de IA generativa. Novidades nossas e de outros!</li>
      </ul>
      </div> </div>
      {/* <h2>Você Está Protegido</h2>
      <p>Todos os pagamentos estão cobertos pelo nosso programa de Garantia de Devolução do Dinheiro em 7 Dias. Aplicam-se termos de reembolso.</p>
   */}
   </div> 
  );
}

export default About;
