import React from "react";
import "../styles/Header.css";
import ReactGA from 'react-ga4';





function Header() {

    return (
        <div className="header">
            <div className="header_title">
                <div>RenderRefine</div>
                <div className="weirdo" >by 3DGuru.ai</div>
            </div>
            <div className="header_subtitle">
                <h3>Gere renders a partir de capturas 3D conceituais.</h3>
                <h3>Nossa IA é especializada em transformar visualizações de Sketch em Render </h3><h3>em poucos segundos, garantindo alta resolução e qualidade de apresentação. </h3>
            </div>
            <div className="header_button">
                <button onClick={()=>{
                    //scroll to the main content
                    //check if page is /contact, then locate to "/" first"
                    if(window.location.pathname === "/Contact"){
                        window.location.href = "/";
                    } else {
                    window.scrollTo({
                        top: window.innerHeight,
                        behavior: "smooth"
                    });}
                }}
className="primary">Saiba Mais</button>
                <button 
                onClick={()=>{
                    //redirect to the payment page
                    ReactGA.send({
                        hitType: 'event',
                        eventCategory: 'Button',
                        eventAction: 'click',
                        eventLabel: 'Buy Now'
                    });
                    
                    window.location.href = "/payment";
                    

                }}
                className="secondary">Compre Agora</button>
            </div>
        </div>
    );
}

export default Header;