import React from "react";

function Blog() {
    return (
        <div>
        <h1>Blog Page</h1>
        <p>Welcome to the Blog Page</p>
        </div>
    );
    }

export default Blog;
