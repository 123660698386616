import React from "react";
import { Link } from "react-router-dom";
import "../styles/Menu.css";
import Logo from "../images/logo.png";

function Menu() {
    return (
        <nav className="navbar">
            <img src={Logo} alt="logo" style={{width:"25px"}}/>
            <div className="menu_items">
                    <Link to="/">Home</Link>
                    <Link to="/">Blog</Link>
                    <Link to="/Contact">Contato</Link>
                    <Link to="/Payment">Preços</Link>
            </div>
            <div className="menu_items">
                {/* <Link to="/Login">Login / SignUp</Link> */}
            </div>
        </nav>
    );
}

export default Menu;
