import React from "react";
import ReactDOM from "react-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import App from "./App";
import "./index.css";


const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

console.log("Clerk Publishable Key:", PUBLISHABLE_KEY);

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk publishable key. Make sure to add NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY in your .env.local file.");
}



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>

    <App />
    </ClerkProvider>

);