import React, { useState, useEffect } from "react";
import axios from 'axios';
import emailjs from 'emailjs-com';
import Menu from "../components/Menu";
import Header from "../components/Header";
import "../styles/Payment.css";

function Payment() {

    const purchaseTrial = async () =>  {
        const templateParams = {
          // parâmetros do template como 'to_name', 'from_name', etc.
        };
      
        emailjs.send('service_85ko3r9', 'template_j6jkdab', templateParams, '0Y9gFVuWVLsOWc9MZ')
          .then((response) => {
            console.log('Email successfully sent!', response);
          })
          .catch((error) => {
            console.error('Failed to send email. Error: ', error);
          });
      }




    // useEffect(() => {
    //     // Cria o elemento script
    //     const script = document.createElement('script');
    //     script.src = "https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js";
    //     script.setAttribute('data-preference-id', '50503572-604811a1-8825-40e7-89a9-6a7569b1f3b6');
    //     script.setAttribute('data-source', 'button');
    //     script.setAttribute('data-button-label', 'Pagar com Mercado Pago');
    //     script.onload = () => {
    //         const button = document.querySelector('.mercadopago-button');
    //         if (button) {
    //             button.classList.add('custom-mercadopago-button');
    //         }
    //     };
    //     document.getElementById('payment-button').appendChild(script);
    // }, []);

    return (
        <div>
            <Menu />
            <div className="header">
                <br/>
                <div className="header_title">
                    <div>RenderRefine</div>
                    <div className="weirdo">by 3DGuru.ai</div>
                </div>   
                <div className="price">
                    <div>R$ 200,00</div>
                    <h3 style={{color:"white", fontSize:"15px"}}>R$1 por imagem | Gere até 200 imagens</h3>
                    <h3>Pagamento único. Créditos vitalícios</h3>
                    <p>Com o RenderRefine você paga apenas uma vez e seus créditos não expiram nunca!</p>

                    <div className="pagamentos">
                    <div className="pagamento">
                        <div id="payment-button">
                            {/* O botão será inserido aqui pelo script do Mercado Pago */}
                        
                        <button className="primary" style={{margin:0}}onClick={()=>{
                            purchaseTrial();
                            alert("Obrigado por querer comprar conosco! Estamos em capacidade máxima de usuários no momento. Por favor, tente novamente mais tarde.");
                        }}>Compre Agora</button>

                        </div>
                      
                    <p style={{marginTop:"25px"}}>Escolha sua forma de pagamento preferida e comece a gerar seus renders sem marca d'água agora mesmo.</p>
                    </div> 

                </div>
                
                </div> 
                <h3>Escolha sua forma de pagamento preferida e comece a gerar seus renders sem marca d'água agora mesmo.</h3>
               <div className="footer">
              
<p>Ao se registrar e usar nosso aplicativo, você concorda com nossos <a 
href="/"
>Termos de Serviço </a>e <a href="/" >Política de Privacidade.</a></p>
  </div>
            </div>
             </div>
    );
}

export default Payment;
