import React , { useEffect, useState }from "react";
import "../styles/Main.css";
import CompareImage from 'react-compare-image';
import About from "./About";
import leftImage from "../images/leftImage.png";
import rightImage from "../images/rightImage.png";
import loadingImage from "../images/loading.gif";
import ReactGA from 'react-ga4';



function Main() {

    const [left, setLeft] = useState(leftImage);
    const [right, setRight] = useState(rightImage);
    const [fileToSend, setFileToSend] = useState(null); // Mudança de nome para claridade
    const [openFAQIndex, setOpenFAQIndex] = useState(null);
    
    function downloadWithWatermark() {
        const image = new Image();
        image.crossOrigin = "Anonymous";  // Adicione esta linha
        image.src = right;  // Usa a URL da imagem processada
        image.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
    
            canvas.width = image.width;
            canvas.height = image.height;
    
            // Desenha a imagem no canvas
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    
            // Configurações da marca d'água
            const watermarkText = "3DGuru.ai";
            ctx.font = "264px Arial";
            ctx.fillStyle = "white";
            ctx.globalAlpha = 0.5;  // Opacidade da marca d'água
            ctx.textAlign = "center";
            ctx.fillText(watermarkText, canvas.width / 2, canvas.height / 2);
    
            // Cria o link para download
            const a = document.createElement("a");
            a.download = "result.jpg";
            a.href = canvas.toDataURL("image/jpeg");
            a.click();
        };
        image.onerror = function() {
            console.error("Não foi possível carregar a imagem com as configurações CORS.");
        };
    }
    

    const faqData = [
        { 
            question: "O aplicativo RenderRefine é fácil de usar para iniciantes?", 
            answer: "Sim, o RenderRefine foi projetado com uma interface extremamente direta e simples, tornando-o acessível e intuitivo para usuários de todos os níveis, especialmente iniciantes." 
        },
        { 
            question: "Posso usar o RenderRefine para projetos pessoais ou comerciais?", 
            answer: "Sim, você pode usar as imagens geradas pelo RenderRefine para projetos pessoais e comerciais sem limitações." 
        },
        { question: "Como obter os melhores resultados com o RenderRefine?",
          answer: "Para obter os melhores resultados com o RenderRefine, recomendamos que você suba uma imagem do seu 3D criado com Sketchup ou software similar que apresente geometrias claras, sem linhas de contorno, e com sombra ativada. Isso garantirá que a IA possa interpretar corretamente o seu modelo e gerar uma imagem de alta qualidade."
    },
        { 
            question: "Qual é o tempo médio de processamento para gerar imagens?", 
            answer: "As imagens do RenderRefine são processadas em menos de 20 segundos! Este número é nossa média, mas o tempo de processamento pode variar dependendo da complexidade do esboço e dos servidores aos quais estamos conectados." 
        } ,
        { 
            question: "Por que o RenderRefine é diferente de uma ferramenta de renderização tradicional?",
            answer: "O RenderRefine é diferente porque é uma ferramenta de renderização baseada em IA que não requer instalação de software ou hardware. Você pode criar imagens de alta qualidade instantaneamente, economizando tempo e dinheiro. Além disso, o RenderRefine é acessível e fácil de usar, mesmo para iniciantes."
        },
        {
            question: "Por que o RenderRefine é melhor que outras ferramentas de renderização baseadas em IA?",
            answer: "Na RenderRefine nós entendemos que você precisa transformar modelos 3D de projetos reais, com geometrias e materiais já definidos por você, em imagens de alta qualidade, de forma rápida e fácil, sem espaço para alucinações, e sem complicações como prompts, parâmetros que nunca parecem bons ou certos o suficiente. Nossa ferramenta foi projetada para atender a essas necessidades, te levando do Sketch ao Render em menos de 20 segundos."
        },
        {
            question: "O que acontece se eu não estiver satisfeito com o resultado?",
            answer: "Se você não estiver satisfeito com o resultado, entre em contato conosco para que possamos entender o que deu errado e ajudá-lo a obter o resultado desejado. Se não conseguirmos atender às suas expectativas, oferecemos uma garantia de devolução do dinheiro em 7 dias."
        }
    
    ];

    

    const toggleFAQ = (index) => {
        setOpenFAQIndex(openFAQIndex === index ? null : index);
    };

    useEffect(() => {
        console.log('useEffect')
        if (fileToSend !== null) { 
            console.log('useEffect dentro do if')
            uploadImage(fileToSend);
        }
    }, [fileToSend]);
    

  
      function makeApiCall(base64Data) {
        
        console.log('chamando api')
  
        fetch('https://3igk2eqyaggc2c2w2aqacxmgcm0pxaed.lambda-url.us-east-1.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              image_data_b64: base64Data,
              prompt:'photo of a house, sunny day foggy view, hyper realistic view, 8k, sharp details, high quality photo, midjourney sky',
              creativity: 0.4,
              resemblance : 1.3,
              scale_factor : 2
          })
        })
        .then(response => response.json())
        .then(data => {
         
         setRight(data.url)
         ReactGA.send({
            hitType: 'event',
            eventCategory: 'Image',
            eventAction: 'Upload',
            eventLabel: 'User Image Upload'
          });
        })

        .catch(error => {
          
          console.error('Error:', error)
        })
      }
    
    
    function resizeImage(file, maxWidth, maxHeight, quality, callback) {
        console.log("resizeImage")
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > height) {
            if (width > maxWidth) {
                height = height * (maxWidth / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = width * (maxHeight / height);
                height = maxHeight;
            }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
            callback(blob);
        }, 'image/jpeg', quality);
    };
}



function uploadImage(blob) {
    console.log('uploadImage')
    const reader = new FileReader();

    reader.onloadend = () => {
        makeApiCall(reader.result);
    }

    reader.readAsDataURL(blob);
}

function handleImageResizeAndSet(file) {
    console.log('handleImageResizeAndSet')
    resizeImage(file, 1600, 900, 0.7, (resizedBlob) => {
        if (resizedBlob.size / 1024 <= 900) { // Verifica se o tamanho do arquivo é menor que 900 KB
            setFileToSend(resizedBlob);
        } else {
            console.error("Image is still larger than 900KB after resize.");
        }
    });
}


    return (
        <div className="main">
          {/* teste agora sem custo */}
            <div className="main_title">
            <h3>Teste agora sem custo. Arraste sua imagem aqui para ver seu resultado em instantes.
                    <span className="curved-arrow">⤵</span>
                    
                </h3>
            
                   <h3 className="dica"> DICA: Para ficar muito bom, oculte as linhas e ative as sombras no seu modelo 3D.</h3>
                </div>
            <div className="main_content">
            <div 
                className="drop_area"
                onDragOver={(e) => {
                    e.preventDefault();
                }}
                onDrop={(e) => {
                    e.preventDefault();
                    const files = e.dataTransfer.files;
                    if (files.length > 0) {
                        const file = files[0];
                        setLeft(URL.createObjectURL(file));  // Mantém a visualização da imagem original
                        handleImageResizeAndSet(file);
                        setRight(loadingImage);
                    }
                }}
                style={{ position: 'relative' }}  // Adicione position relative aqui
            >
                <CompareImage 
                    leftImage={left}
                    rightImage={right}
                    sliderLineColor="dodgerblue"
                />
                <div className="watermark">3DGuru.ai</div>  {/* Adicione a marca d'água aqui */}
            </div>
                    <h3 style={{cursor:"pointer"}} onClick={downloadWithWatermark}
                    ><br/>Baixe o resultado 💾</h3>

                    </div>
                    <About />
                    <div id="faqs" className="FAQ">
                        <h2>FAQs</h2>
                        {faqData.map((item, index) => (
                        <div key={index} className="FAQ_item" onClick={() => toggleFAQ(index)}>
                            <p className="question">{item.question}</p>
                            {openFAQIndex === index && <p className="answer">{item.answer}</p>}
                        </div>
                    ))}
                      
                    </div>


        </div>
    );
}

export default Main;