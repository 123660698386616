import React from 'react';
import { SignIn } from '@clerk/clerk-react';

function Login() {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <SignIn />
        </div>
    );
}

export default Login;
