import React, { useState } from "react";
import emailjs from 'emailjs-com';
import Menu from "../components/Menu";
import Header from "../components/Header";
import "../styles/Contact.css";

function Contact() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const sendEmail = async (e) => {
        e.preventDefault(); // Impede o comportamento padrão do formulário (refresh da página)

        const templateParams = {
            from_name: name, 
            from_email: email,// 'name' deve ser o estado que guarda o nome do usuário
            to_name: "3DGuru.ai", // Este pode ser um valor fixo ou variável, dependendo do seu caso de uso
            message: message 
        };

        emailjs.send('service_85ko3r9', 'template_5jzw6gj', templateParams, '0Y9gFVuWVLsOWc9MZ')
        .then((response) => {
            console.log('Email successfully sent!', response);
            alert("Mensagem enviada com sucesso!");
        })
        .catch((error) => {
            console.error('Failed to send email. Error: ', error);
            alert("Erro ao enviar mensagem!");
        });
    };

    return (
        <div>
            <Menu />
            <Header />
            <div className="main">
                <h1>Entre em Contato conosco</h1>
                <p>Nos envie uma mensagem e responderemos o mais rápido possível.</p>
                <form className="form" onSubmit={sendEmail}>
                    <input type="text" placeholder="Seu nome" value={name} onChange={(e) => setName(e.target.value)} />
                    <input type="email" placeholder="Seu email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <textarea placeholder="Sua mensagem" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                    <button className="primary" type="submit">Enviar</button>
                </form>
            </div>
        </div>
    );
}

export default Contact;
